<style>
  .PlanejamentoContratoForm {
    margin-bottom: 100px;
  }
  .PlanejamentoContratoForm .card-title {
    font-size: 18px;
    font-weight: 500;
  }
  .PlanejamentoContratoForm .card-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
</style>
<template>
  <div class="PlanejamentoContratoForm" v-if="carregouEstrutura">

    <component v-for="(p, index) in componentesPassos" :key="index"
      :is="p.componente"
      :ref="p.nome"
      v-show="exibePasso(p.componente)"
      :tipo-contrato="tipoContrato"
      :configuracao="configuracao"
      :contrato-edicao="contratoEdicao"
      :edicao="edicao"
      :novo-contrato="novo"
      :alterarFinalizado="alterarFinalizado"
      :copia="copia"
      :novo="novo"
      :somente-leitura="naoPodeEditar"
      :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
      :tem-fluxo-contrato="temFluxoContrato"
      :get-objeto-outro-passo="getObjetoOutroPasso"
      @PlanejamentoContratoFormAcoes__carregarContrato="carregarContrato"
      @PlanejamentoContratoFormAcaoes__atualizarStatusContrato="atualizarStatusContrato"
      @PLANEJAMENTO_CONTRATO_PASSO_ANTERIOR="() => $refs.rodapeAcoes.anterior()"
      @PlanejamentoContratoFormAcoes__setValorContrato="setValorContrato">
    </component>

    <div class="PlanejamentoContratoForm_Acoes" v-if="carregouEstrutura">
      <planejamento-contrato-form-rodape
        ref="rodapeAcoes"
        :definicao-passos="definicaoPassos"
        :alterarFinalizado="alterarFinalizado"
        :copia="copia"
        :edicao="edicao"
        :novo="novo"
        :somente-leitura="naoPodeEditar"
        :contrato-edicao="contratoEdicao"
        :get-componentes-passos="getComponentesPassos"
        :valida-componente-passo="validaComponentePasso"
        @PlanejamentoContratoFormRodape__MudaPasso="mudaPasso"
        @PlanejamentoContratoFormRodape__podeSolicitarAprovacaoContrato
            ="preencherPodeSolicitarAprovacaoContrato"
        @PlanejamentoContratoFormRodape__temFluxoContrato="preencherTemFluxoContrato"
        />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import definicaoPassos from '@/spa/planejamento-contrato/form/planejamentoDefinicaoPassos';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

import PlanejamentoContratoFormRodape from './PlanejamentoContratoFormRodape';
import ContratoConfiguracao from '../ContratoConfiguracao';

export default {
  components: {
    ...definicaoPassos.componentes,
    PlanejamentoContratoFormRodape,
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    tituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.nome : '';
    },
    subtituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.descricao : '';
    },
    carregouEstrutura() {
      return this.tipoContrato != null && this.configuracao != null;
    },
    contratoAprovado() {
      return this.statusContrato === 'APROVADO';
    },
    naoPodeEditar() {
      return this.somenteLeitura || this.contratoAprovado
        || !this.canEdit;
    },
    renderizaFormApuracao() {
      return !this.novo && this.objetoPassoGeral != null && this.contratoEdicao.id != null;
    },
    componentesPassos() {
      return Object.keys(this.passosHabilitados)
        .filter((nome) => this.passosHabilitados[nome].habilitado)
        .map((nome) => ({
          nome,
          componente: this.passosHabilitados[nome].componente,
        }));
    },
    passosHabilitados() {
      return this.definicaoPassos.passosHabilitados(this, this.contratoEdicao);
    },
  },
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    somenteLeitura: Boolean,
  },
  data() {
    return {
      tipoContratoResource: this.$api.tipoContrato(this.$resource),
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      configuracao: null,
      podeSolicitarAprovacaoContrato: false,
      temFluxoContrato: false,
      tipoContrato: null,
      contratoEdicao: {},
      objetoPassoGeral: null,
      camposDeProdutoDoContrato: null,
      statusContrato: null,

      passoAberto: 1,
      definicaoPassos,
    };
  },
  methods: {
    setValorContrato(valor) {
      this.componentesPassos.filter((c) => this.$refs[c.nome][0].setValorContrato)
        .forEach((c) => this.$refs[c.nome][0].setValorContrato(valor));
    },
    preencherPodeSolicitarAprovacaoContrato(podeSolicitarAprovacaoContrato) {
      this.podeSolicitarAprovacaoContrato = podeSolicitarAprovacaoContrato;
    },
    preencherTemFluxoContrato(temFluxoContrato) {
      this.temFluxoContrato = temFluxoContrato;
    },
    exibePasso(componente) {
      return this.componentesPassos
        .map((p) => p.componente)
        .indexOf(componente) + 1 === this.passoAberto;
    },
    mudaPasso(passo) {
      if (passo > this.passoAberto) {
        this.avancaPasso(passo);
      }
      this.passoAberto = passo;
    },
    avancaPasso(passo) {
      const ref = Object.keys(this.passosHabilitados)[passo - 1];
      this.$refs[ref][0].abrePasso();
    },
    carregarContrato(idContrato) {
      this.idContrato = idContrato;

      this.planejamentoContratoListagemResource.buscarContrato({ idContrato })
        .then((res) => {
          this.contratoEdicao = { ...res.data };
          this.statusContrato = this.contratoEdicao.passoGeral.status;
          return this.contratoEdicao.passoGeral.idContratoTipo;
        }).then((idContratoTipo) => {
          Promise.all([
            this.carregarTipoContrato(idContratoTipo),
            this.carregarConfiguracao(idContratoTipo),
          ]).then(() => {
            if (this.$refs.componenteCampos) {
              setTimeout(() => {
                this.$refs.componenteCampos.montaObjetoContrato();
              });
            }
            if (this.$refs.componenteAcoes) {
              setTimeout(() => {
                this.$refs.componenteAcoes.acoesCarregadas();
              });
            }
            setTimeout(() => this.$refs.rodapeAcoes.carregaInformacoesFluxo(idContrato));
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoContrato) {
      const { idContrato } = this;

      const promise = !this.novo
        ? this.configuracaoResource.buscarConfigSnapshot({ idContrato })
        : this.configuracaoResource.buscarConfigVigente({ idTipoContrato });

      return promise.then((res) => {
        this.configuracao = new ContratoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTipoContrato(idTipoContrato) {
      return this.tipoContratoResource.buscarTipoContratoComConfiguracao({ idTipoContrato })
        .then((res) => {
          this.tipoContrato = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    validaComponentePasso() {
      const ref = Object.keys(this.passosHabilitados)[this.passoAberto - 1];
      const componente = this.$refs[ref][0];
      if (componente.valida) {
        return componente.valida();
      }
      return true;
    },
    getObjetoOutroPasso(passo) {
      const componente = this.$refs[passo][0];
      if (componente.getObjetoPasso) {
        return componente.getObjetoPasso();
      }
      return null;
    },
    getComponentesPassos() {
      const componentes = [];
      this.componentesPassos.map((c) => c.nome).forEach((ref) => {
        const [referencia] = this.$refs[ref];
        componentes[ref] = referencia;
      });
      return componentes;
    },
    atualizarStatusContrato(statusContrato) {
      this.statusContrato = statusContrato;
      this.$refs.componenteCampos.setStatusContrato(statusContrato);
    },
  },
  mounted() {
    if (this.novo) {
      const { idTipoContrato } = this.$route.params;
      this.carregarTipoContrato(idTipoContrato);
      this.carregarConfiguracao(idTipoContrato);
    } else {
      const { idContrato } = this.$route.params;
      this.carregarContrato(idContrato);
    }
  },
};
</script>
