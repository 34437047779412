<template>
  <v-card class="PlanejamentoContratoFormCampos__Card--treslinhas">
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" >
          <v-select
            v-model="focoContrato"
            :items="focoContratoConfiguracao"
            :disabled="somenteLeitura || unicoFocoContrato"
            return-object
            :label="$t('label.foco')"
            item-text="texto"
            :item-value="['valor', 'indiceRecursao']"
            @change="alterarTipo">
          </v-select>
        </v-col>
        <v-col
          cols="12" v-if="focoContrato">
          <v-autocomplete
            v-if="exibeFocoHolding"
            id="planejamento-autocomplete-holding"
            class="custom-autocomplete"
            v-model="contrato.holding"
            return-object
            :rules="rules.requiredFunction('holding', 'nomExtensao')"
            :items="holdings"
            :label="`${$tc('label.holding', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomExtensao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoContrato"
            :placeholder="(somenteLeitura || desabilitaFocoContrato) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="holdingAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-holding')"
            @click.native="(i) => buscaAutocomplete(i, buscaHoldings)"
            :search-input.sync="triggerHolding">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-cliente"
            class="custom-autocomplete"
            v-else-if="exibeFocoCliente"
            v-model="contrato.cliente"
            return-object
            :rules="rules.requiredFunction('cliente', 'codNome')"
            :items="clientes"
            :label="`${$tc('label.cliente', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaCliente"
            :placeholder="(somenteLeitura || desabilitaCliente) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="clienteAlterado"
            @click:append="() => triggerSelecao('planejamento-autocomplete-cliente')"
            @click.native="(i) => buscaAutocomplete(i, buscaClientes)"
            :search-input.sync="triggerCliente">
          </v-autocomplete>
          <v-autocomplete
            v-else-if="focoContrato"
            id="planejamento-autocomplete-extensao-cliente"
            class="custom-autocomplete"
            v-model="contrato.extensaoCliente"
            return-object
            :items="extensoesCliente"
            :rules="rules.requiredFunction('extensaoCliente', 'descricao')"
            :label="`${textoFocoContrato} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="descricao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoContrato || desabilitarExtensaoCliente"
            :placeholder="(somenteLeitura || desabilitaFocoContrato || desabilitarExtensaoCliente) ?
              null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="extensaoClienteAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-extensao-cliente')"
            @click.native="(i) => buscaAutocomplete(i, buscaExtensaoCliente)"
            :search-input.sync="triggerExtensoesCliente">
          </v-autocomplete>
        </v-col>
        <v-col cols="12"
          v-if="exibeClientePagador">
          <v-autocomplete
            id="planejamento-autocomplete-clientepagador"
            class="custom-autocomplete"
            v-model="contrato.clientePagador"
            return-object
            :rules="rules.requiredFunction('clientePagador', 'codNome')"
            :items="clientesPagadores"
            :label="`${$tc('label.cliente_pagador', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaClientePagador"
            :placeholder="(somenteLeitura || desabilitaClientePagador) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-clientepagador')"
            @click.native="(i) => buscaAutocomplete(i, buscaClientesPagadores)"
            :search-input.sync="triggerClientePagador">
          </v-autocomplete>
        </v-col>
      </v-row>
      <div>
      <component v-for="(validacao, index) in validacoes" :key="index"
        :is="validacao.componente"
        :ref="validacao.nome"
        :name="index"
        :somenteLeitura="somenteLeitura"
        :novoContrato="novoContrato"
        :configuracao="configuracao"
        :focoContrato="focoContrato"
        :entidade="contrato.holding ? contrato.holding : contrato.cliente" >
      </component>

      </div>
    </v-container>
  </v-card>
</template>
<script>
import planejamentoContratoValidacao from '@/spa/planejamento-contrato/form/passo-geral/planejamentoContratoValidacao';
import { buscarHoldings, buscarClientes, buscarExtensaoCliente } from '../../../../common/resources/planejamento/planejamento-acao-cadastro';
import { buscaAutocomplete, triggerSelecao } from '../../../../common/functions/autocomplete-utils';

export default {
  props: {
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    contrato: Object,
    novoContrato: Boolean,
    tipoContrato: Object,
  },
  components: {
    ...planejamentoContratoValidacao.componentes,
  },
  computed: {
    focoContratoConfiguracao() {
      const { focoContrato } = this.configuracao;
      focoContrato.forEach((fa) => {
        if (!fa.texto) {
          fa.texto = this.$tc(fa.i18n, 1);
        }
      });
      return focoContrato;
    },
    desabilitaFocoContrato() {
      const { unidadeNegocio } = this.contrato;
      return !unidadeNegocio || !unidadeNegocio.id;
    },
    textoFocoContrato() {
      const foco = this.focoContratoConfiguracao.find((f) => (f.valor === this.focoContrato.valor
        && f.indiceRecursao === this.focoContrato.indiceRecursao));
      return foco ? foco.texto : '';
    },
    unicoFocoContrato() {
      const size = this.focoContratoConfiguracao.length === 1;
      if (size) {
        const [focoCon] = this.focoContratoConfiguracao;
        this.focoContrato = focoCon;
      }
      return size;
    },
    exibeFocoHolding() {
      return this.focoContrato && this.focoContrato.valor === 'HOLDING'
        && !this.focoContrato.indiceRecursao;
    },
    exibeFocoCliente() {
      return this.focoContrato && this.focoContrato.valor === 'CLIENTE'
        && !this.focoContrato.indiceRecursao;
    },
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    desabilitaCliente() {
      const { unidadeNegocio } = this.contrato;
      if (!unidadeNegocio || !unidadeNegocio.id) {
        return true;
      }
      return false;
    },
    desabilitaClientePagador() {
      const { holding } = this.contrato;
      if (!holding || !holding.id) {
        return true;
      }
      return false;
    },
    validacoes() {
      return planejamentoContratoValidacao.validacaoContrato(this);
    },
  },
  watch: {
    triggerHolding(val) {
      const { holding } = this.contrato;
      if (holding && holding.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaHoldings(val);
      }, 500);
    },
    triggerCliente(val) {
      const { cliente } = this.contrato;
      if (cliente && cliente.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientes(val);
      }, 500);
    },
    triggerClientePagador(val) {
      const { clientePagador } = this.contrato;
      if (clientePagador && clientePagador.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientesPagadores(val);
      }, 500);
    },
    triggerExtensoesCliente(val) {
      const { extensaoCliente } = this.contrato;
      if (extensaoCliente && extensaoCliente.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaExtensaoCliente(val);
      }, 500);
    },
  },
  data() {
    return {
      clienteResource: this.$api.cliente(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      holdings: [],
      extensoesCliente: [],
      clientes: [],
      clientesPagadores: [],

      triggerHolding: null,
      triggerExtensoesCliente: null,
      triggerCliente: null,
      triggerClientePagador: null,
      timeoutTrigger: null,

      desabilitarExtensaoCliente: false,

      focoContrato: {},

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
      planejamentoContratoValidacao,
    };
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    alterarTipo() {
      this.contrato.holding = null;
      this.contrato.extensaoCliente = null;
      this.contrato.cliente = null;
      this.contrato.focoAcao = this.focoContrato.texto;
      this.preencherExtensaoCliente();
    },
    preencherExtensaoCliente() {
      const extCarteira = this.configuracao.extensoesCarteira
        .find((c) => c.mnemonico === this.focoContrato);
      if (extCarteira) {
        this.contrato.extensaoCliente = this.contrato[extCarteira.label];
        this.extensoesCliente = [this.contrato.extensaoCliente];
        this.desabilitarExtensaoCliente = true;
      } else {
        this.desabilitarExtensaoCliente = false;
      }
    },
    getContrato() {
      return this.contrato;
    },
    getTextoFoco() {
      return this.focoContrato.texto;
    },
    buscaHoldings(autocomplete) {
      const parametros = {
        autocomplete,
        id_unidadenegocio: [
          this.contrato.unidadeNegocio.id,
        ],
        idPeriodoPlanejamento: this.contrato.periodoPlanejamento
          ? this.contrato.periodoPlanejamento.id
          : null,
      };
      buscarHoldings(parametros, this.$resource)
        .then((res) => {
          this.holdings = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    extensaoClienteAlterada() {
      const { clientePagador, fornecedorPagador } = this.contrato;
      if (clientePagador && clientePagador.id) {
        this.contrato.clientePagador = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.contrato.fornecedorPagador = {};
      }
      this.$emit('PlanejamentoContratoFormFocoAcao__alteradoFoco');
    },
    buscaExtensaoCliente(autocomplete) {
      const parametros = {
        autocomplete,
        id_unidadenegocio: [
          this.contrato.unidadeNegocio.id,
        ],
        ...this.focoContrato,
        mnemonico: this.focoContrato.valor,
      };
      if (this.contrato.divisao && this.contrato.divisao.id) {
        parametros.idDivisao = this.contrato.divisao.id;
      }
      parametros.idExtensoesCarteira = this.recuperarExtensoesCarteiras();
      buscarExtensaoCliente(parametros, this.$resource)
        .then((res) => {
          this.extensoesCliente = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    recuperarExtensoesCarteiras() {
      const idsExtensoesCarteira = this.configuracao.extensoesCarteira
        .map((e) => this.contrato[e.label])
        .filter((e) => !!e)
        .map((e) => e.id);
      if (this.configuracao.unidadeNegocioCarteira && this.contrato.unidadeNegocio
        && this.contrato.unidadeNegocio.id) {
        idsExtensoesCarteira.push(this.contrato.unidadeNegocio.id);
      }
      return idsExtensoesCarteira;
    },
    buscaClientes(autocomplete) {
      const parametros = {
        autocomplete,
        idUnidadeNegocio: this.contrato.unidadeNegocio.id,
      };
      buscarClientes(parametros, this.$resource)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaClientesPagadores(autocomplete) {
      const parametros = {
        autocomplete,
        idHolding: this.contrato.holding.id,
        idUnidadeNegocio: this.contrato.unidadeNegocio.id,
      };
      this.planejamentoAcaoResource.buscarClientesPagadores(parametros)
        .then((res) => {
          this.clientesPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    limpaFocoContrato() {
      const { holding, cliente, clientePagador } = this.contrato;
      if (holding && holding.id) {
        this.contrato.holding = {};
      }
      if (cliente && cliente.id) {
        this.contrato.cliente = {};
      }
      if (clientePagador && clientePagador.id) {
        this.contrato.clientePagador = {};
      }
    },
    holdingAlterada() {
      const { clientePagador } = this.contrato;
      if (clientePagador && clientePagador.id) {
        this.contrato.clientePagador = {};
      }
    },
    clienteAlterado(cliente) {
      this.contrato.clientePagador = cliente ? { ...cliente } : null;
      this.clientesPagadores = [
        this.contrato.clientePagador,
      ];
    },
    trataFocoContrato() {
      if (this.unicoFocoContrato) {
        const [focoCon] = this.focoContratoConfiguracao;
        this.focoContrato = focoCon;
      }
    },
    buscaFocoContrato(mnemonico, indiceRecursao = null) {
      return this.focoContratoConfiguracao.find((el) => el.valor === mnemonico
        && el.indiceRecursao === indiceRecursao) || {};
    },
    setContratoEdicao(contrato) {
      const {
        cliente, holding, extensaoCliente, clientePagador,
      } = contrato;
      if (holding) {
        this.focoContrato = this.buscaFocoContrato('HOLDING');
        this.holdings = [holding];
        this.clientesPagadores = [clientePagador];
      } else if (extensaoCliente) {
        this.focoContrato = this.buscaFocoContrato(extensaoCliente.mnemonico,
          extensaoCliente.indiceRecursao);

        this.extensoesCliente = [extensaoCliente];
        const extCarteira = this.configuracao.extensoesCarteira
          .find((c) => c.mnemonico === this.focoContrato);
        if (extCarteira) {
          this.desabilitarExtensaoCliente = true;
        }
      } else {
        this.focoContrato = this.buscaFocoContrato('CLIENTE');
        this.clientes = [cliente];
        this.clientesPagadores = [clientePagador];
      }
    },
  },
  mounted() {
    this.trataFocoContrato();
  },
};
</script>
