<template>
  <v-dialog v-model="showModal" v-if="acaoSelecionada"  max-width="1200px" style="max-height: 400px">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $tc('message.cancelar_acao', 1) }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <span v-html="$t('message.deseja_cancelar_acao_contrato',
                  { nomeAcaoTipo: acaoSelecionada.nomeTipoAcao, nomeAcaoAgrupadora: acaoSelecionada.nomeTipoAcaoAgrupadora })">
              </span>
              <v-textarea
                id="justificativa_obrigatoria"
                name="justificativa-obrigatoria"
                :label="`${$tc('label.justificativa', 1)} *`"
                v-model="justificativa"
                auto-grow
                autofocus
                rows="1"
                counter="250"
                :rules="[rules.required, rules.maxLength250]"
              ></v-textarea>
              <v-data-table
                :headers="headers"
                :items="apuracoesImpactadas"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 20, 30],
                }">
                <template v-slot:item.cod_apuracao="{ item }">
                  {{ item.codApuracao != 0 ? item.codApuracao : $t('label.nova') }}
                </template>
                <template v-slot:item.status="{ item }">
                  {{ $tc(`status_entidade.${item.status.toLowerCase()}`, 1) }}
                </template>
                <template v-slot:item.data_inicio="{ item }">
                  {{ item.dataInicio }}
                </template>
                <template v-slot:item.data_fim="{ item }">
                  {{ item.dataFim }}
                </template>
                <template v-slot:item.atualiza_apuracao="{ item }">
                  {{ item.atualizaApuracao ? $t('label.impacto_apuracoes_agrupadas_atualizacao') : $t('label.impacto_apuracoes_agrupadas_inclusao')}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="fechar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="confirmarCancelamento">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    cancelarAcao: Function,
    observacao: String,
    mensagem: String,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      showModal: false,
      acaoSelecionada: null,
      apuracoesImpactadas: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 5,
        page: 1,
      },
      justificativa: '',
      headers: [
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'cod_apuracao', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.status_apuracao', 1), value: 'status', width: '20%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.inicio_apuracao', 1), value: 'data_inicio', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.fim_apuracao', 1), value: 'data_fim', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.descricao_impacto', 1), value: 'atualiza_apuracao', align: 'center', sortable: false,
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maxLength250: (v) => (v && v.length <= 250) || this.$t('message.limite_caracteres_excedido'),
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.acaoSelecionada) {
          this.buscarApuracoesImpactadas(this.acaoSelecionada);
        }
      },
      deep: true,
    },
  },
  methods: {
    open(acao) {
      this.acaoSelecionada = acao;
      this.justificativa = '';
      this.showModal = true;
    },
    fechar() {
      this.showModal = false;
      this.acaoSelecionada = null;
    },
    buscarApuracoesImpactadas(acao) {
      Object.assign(this.acaoSelecionada, acao);
      const params = {
        idAcao: acao.id,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };
      this.apuracoesImpactadas = [];
      if (this.acaoSelecionada && !!this.acaoSelecionada.passoGeral.idAcaoAgrupadora) {
        params.idAcaoAgrupadora = this.acaoSelecionada.passoGeral.idAcaoAgrupadora;
      }

      this.workflowAcaoResource.buscarApuracoesImpactadasPorCancelamentoAcao(params)
        .then((res) => {
          if (res.data) {
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.apuracoesImpactadas = res.data.resposta;
            this.$refs.form.resetValidation();
          }
        })
        .catch((err) => this.$error(this, err));
    },
    confirmarCancelamento() {
      if (!this.$refs.form.validate()) return;
      this.cancelarAcao(this.justificativa);
      this.fechar();
    },
  },
  mounted() {
  },
};
</script>
