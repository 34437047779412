<template>
  <v-card class="PlanejamentoContratoFormCampos__Card--treslinhas">
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" >
          <v-autocomplete
            id="planejamento-autocomplete-divisao"
            class="custom-autocomplete"
            v-model="contrato.divisao"
            return-object
            :rules="rules.requiredFunction('divisao', 'nome')"
            :items="divisoesFiltradas"
            :label="`${$tc('label.divisao', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaDivisao"
            :clearable="true"
            @input="divisaoAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
            @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" >
          <v-autocomplete
            id="planejamento-autocomplete-unidadenegocio"
            class="custom-autocomplete"
            v-model="contrato.unidadeNegocio"
            return-object
            :rules="rules.requiredFunction('unidadeNegocio', 'nomExtensao')"
            :items="unidadesNegocioDivisao"
            :label="`${$tc('label.unidade_negocio', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomExtensao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaUnidadeNegocio"
            :clearable="true"
            @input="unidadeNegocioAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
            @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="configuracao.temExtensaoCarteira"
          v-for="(ec, index) in configuracao.extensoesCarteira" :key="index"
          cols="12" class="py-0">
          <v-autocomplete
            autocomplete="off"
            :id="`planejamento-autocomplete-carteira-${ec.label}`"
            class="custom-autocomplete"
            v-model="contrato[ec.label]"
            return-object
            :items="listasExtensoesCarteira[ec.label]"
            :rules="rules.requiredFunction(ec.label, 'nomExtensao')"
            :label="`${ ec.descricao } *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="descricao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaExtensaoCarteira ||
              (listasExtensoesCarteira[ec.label] && listasExtensoesCarteira[ec.label].length === 1)"
            :clearable="true"
            @input="extensaoAlterada">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="!vigenciaPeriodo">
        <v-col cols="12" md="6">
          <metadados-campo-dinamico-data
            :metadados="definicaoDtaInicio"
            v-model="contrato.dtaVigenciaInicio"
            :somente-leitura="somenteLeitura || vigenciaHerdada"
            :objeto-container="contrato"/>
        </v-col>
        <v-col cols="12" md="6">
          <metadados-campo-dinamico-data
            :metadados="definicaoDtaFim"
            v-model="contrato.dtaVigenciaFim"
            :somente-leitura="somenteLeitura || vigenciaHerdada"
            :objeto-container="contrato"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-autocomplete
            id="planejamento-autocomplete-periodo"
            class="custom-autocomplete"
            v-model="contrato.periodoPlanejamento"
            return-object
            :rules="rules.requiredFunction('periodoPlanejamento', 'nome')"
            :items="periodosPlanejamento"
            :label="`${$tc('label.periodo', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaPeriodo"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-periodo')"
            @click.native="(i) => buscaAutocomplete(i, buscarPeriodosPlanejamento)">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import moment from 'moment';
import MetadadosCampoDinamicoData from '../../../../shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import { buscaAutocomplete, triggerSelecao } from '../../../../common/functions/autocomplete-utils';

export default {
  props: {
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    contrato: Object,
    tipoContrato: Object,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      definicaoDtaInicio: {
        labelCampo: 'data_inicio',
        nomCampoId: 'dtaInicio',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      definicaoDtaFim: {
        labelCampo: 'data_fim',
        nomCampoId: 'dtaFim',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },

      divisoesFiltradas: [],
      divisoesUsuario: [],
      unidadesNegocioDivisao: [],
      periodosPlanejamento: [],

      listasExtensoesCarteira: {},

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
    };
  },
  components: {
    MetadadosCampoDinamicoData,
  },
  computed: {
    desabilitaExtensaoCarteira() {
      const { divisao } = this.contrato;
      return !divisao || !divisao.id;
    },
    vigenciaPeriodo() {
      const { tipoVigencia } = this.configuracao;
      return ['VIGENCIA_PERIODO'].includes(tipoVigencia);
    },
    vigenciaHerdada() {
      const { tipoVigencia } = this.configuracao;
      const herdarPeriodo = ['HERDAR_PERIODO_ACAO'].includes(tipoVigencia);

      if (herdarPeriodo && this.novoContrato) {
        this.contrato.dtaVigenciaInicio = this.moment().format('YYYY-MM-DD');
        this.contrato.dtaVigenciaFim = this.moment().format('YYYY-MM-DD');
      }
      return herdarPeriodo;
    },
    desabilitaDivisao() {
      return !this.novoContrato || this.divisoesUsuario.length <= 1;
    },
    desabilitaUnidadeNegocio() {
      const { divisao } = this.contrato;
      if (!divisao || !divisao.id || this.unidadesNegocioDivisao.length <= 1) {
        return true;
      }
      return false;
    },
    desabilitaPeriodo() {
      return this.periodosPlanejamento.length === 1;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    buscarExtensoesCarteira() {
      if (!this.configuracao.temExtensaoCarteira) return;
      this.listasExtensoesCarteira = {};
      this.configuracao.extensoesCarteira
        .forEach((e) => this.buscarExtensaoCarteira(e.mnemonico, e.label));
    },
    buscarExtensaoCarteira(mnemonico, label) {
      if (this.contrato[label] && this.contrato[label].id) {
        this.contrato[label] = {};
      }
      const idDivisao = this.contrato.divisao.id;
      const parametros = { idDivisao, mnemonico };
      return this.planejamentoAcaoResource
        .buscarExtensaoDivisao(parametros)
        .then((res) => {
          this.listasExtensoesCarteira[label] = res.data;
          if (this.listasExtensoesCarteira[label].length === 1) {
            const extensao = this.listasExtensoesCarteira[label][0];
            this.contrato[label] = { ...extensao };
          }
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDivisoes(autocomplete) {
      this.divisoesFiltradas = this.divisoesUsuario
        .filter((divisao) => divisao.nome.indexOf(autocomplete) >= 0);
    },
    preencherExtensoesCarteiras(contrato) {
      this.configuracao.extensoesCarteira.forEach((e) => {
        if (!contrato[e.label]) {
          return;
        }
        const extensao = contrato[e.label];
        extensao.descricao = `${extensao.idExterno} - ${extensao.nomExtensao}`;
        this.listasExtensoesCarteira[e.label] = [extensao];
      });
    },
    montaObjetoContrato(contrato) {
      this.preencherExtensoesCarteiras(contrato);
    },
    extensaoAlterada() {
      this.$emit('PlanejamentoContratoForm__CampoProdutoAlterado');
      if (this.$refs.formFocoContrato) {
        this.$refs.formFocoContrato.limpaFocoAcao();
      }
    },
    buscarDivisoesUsuario() {
      this.planejamentoAcaoResource.buscarDivisoes()
        .then((res) => {
          this.divisoesUsuario = res.data;
          this.divisoesFiltradas = res.data;
          if (this.divisoesUsuario.length === 1) {
            const unicaDivisao = this.divisoesUsuario[0];
            this.contrato.divisao = { ...unicaDivisao };
            this.buscarUnidadesNegocioDivisao();
            this.buscarExtensoesCarteira();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesNegocioDivisao(autocomplete) {
      const idDivisao = this.contrato.divisao.id;
      const parametros = { idDivisao, autocomplete };
      return this.planejamentoAcaoResource
        .buscarUnidadesNegocioDivisao(parametros)
        .then((res) => {
          this.unidadesNegocioDivisao = res.data;
          if (this.unidadesNegocioDivisao.length === 1) {
            const unicaUnidade = this.unidadesNegocioDivisao[0];
            this.contrato.unidadeNegocio = { ...unicaUnidade };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarPeriodosPlanejamento(autocomplete) {
      return this.planejamentoAcaoResource
        .buscarPeriodosVigentePlanejamento({ autocomplete })
        .then((res) => {
          this.periodosPlanejamento = res.data;
          if (this.periodosPlanejamento.length === 1) {
            const unicoPeriodo = this.periodosPlanejamento[0];
            this.contrato.periodoPlanejamento = { ...unicoPeriodo };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    divisaoAlterada() {
      if (this.contrato.unidadeNegocio) {
        this.contrato.unidadeNegocio = {};
      }
      if (this.contrato.divisao) {
        this.contrato.divisao.codNome = this.contrato.divisao.id;
        this.buscarUnidadesNegocioDivisao();
        this.buscarExtensoesCarteira();
      }
    },
    unidadeNegocioAlterada() {
      this.$emit('PlanejamentoContratoForm__CampoProdutoAlterado');
    },
    validarDatas() {
      const dataInicial = moment(this.contrato.dtaVigenciaInicio);
      const dataFinal = moment(this.contrato.dtaVigenciaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    getContrato() {
      const {
        divisao,
        unidadeNegocio,
        periodoPlanejamento,
        dtaVigenciaInicio,
        dtaVigenciaFim,
      } = this.contrato;

      return {
        idDivisao: divisao.id,
        idUnidadeNegocio: unidadeNegocio.id,
        idPeriodo: periodoPlanejamento ? periodoPlanejamento.id : null,
        dtaVigenciaInicio,
        dtaVigenciaFim,
      };
    },
    setContratoEdicao(contrato) {
      const { unidadeNegocio, periodoPlanejamento, divisao } = contrato;
      this.divisoesFiltradas = [divisao];
      this.unidadesNegocioDivisao = [unidadeNegocio];
      if (periodoPlanejamento) {
        this.periodosPlanejamento = [periodoPlanejamento];
      }
      this.montaObjetoContrato(contrato);
    },
  },
  mounted() {
    if (this.novoContrato) {
      if (!this.somenteLeitura) {
        this.buscarDivisoesUsuario();
      }
      if (this.vigenciaHerdada) {
        this.contrato.dtaVigenciaInicio = this.moment().format('YYYY-MM-DD');
        this.contrato.dtaVigenciaFim = this.moment().format('YYYY-MM-DD');
      } else if (this.vigenciaPeriodo) {
        this.buscarPeriodosPlanejamento();
      }
    }
  },
};
</script>
