import PlanejamentoContratoFormAcoes from '@/spa/planejamento-contrato/form/passo-acoes/PlanejamentoContratoFormAcoes';
import PlanejamentoContratoFormApuracao from '@/spa/planejamento-contrato/form/passo-apuracao/PlanejamentoContratoFormApuracao';
import PlanejamentoContratoFormCampos from './passo-geral/PlanejamentoContratoFormCampos';

export default {
  componentes: {
    PlanejamentoContratoFormCampos,
    PlanejamentoContratoFormAcoes,
    PlanejamentoContratoFormApuracao,
  },
  passosHabilitados(ref, contrato) { // eslint-disable-line
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-contrato-form-campos',
      },
      acoes: {
        habilitado: true,
        label: ref.$tc('label.acao', 2),
        componente: 'planejamento-contrato-form-acoes',
      },
      apuracao: {
        habilitado: false,
        label: null,
        componente: 'planejamento-contrato-form-apuracao',
      },
      simulador: {
        habilitado: false,
        label: null,
      },
    };
  },
};
