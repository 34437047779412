export default {
  computed: {
    podeSolicitarAprovacao() {
      return this.edicao && this.contratoEdicao.passoGeral && this.possuiFluxoAprovacao
        && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO' && this.solicitanteFluxo
        && this.possuiAcoesEmCadastro;
    },
    podeAprovar() {
      return this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    podeFinalizar() {
      return this.contratoEdicao.passoGeral && !this.possuiFluxoAprovacao
        && this.contratoEdicao.passoGeral.status !== 'APROVADO';
    },
    possuiAcoesEmCadastro() {
      return this.contratoEdicao.passoAcoes.acoes
        .some((acao) => acao.passoGeral.status === 'EM_CADASTRO');
    },
  },
  methods: {
    exibicaoReprovarPersonalizada() {
      return true;
    },
  },
};
