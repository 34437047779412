var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.acaoSelecionada)?_c('v-dialog',{staticStyle:{"max-height":"400px"},attrs:{"max-width":"1200px"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$tc('message.cancelar_acao', 1))+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('message.deseja_cancelar_acao_contrato',
                { nomeAcaoTipo: _vm.acaoSelecionada.nomeTipoAcao, nomeAcaoAgrupadora: _vm.acaoSelecionada.nomeTipoAcaoAgrupadora }))}}),_c('v-textarea',{attrs:{"id":"justificativa_obrigatoria","name":"justificativa-obrigatoria","label":((_vm.$tc('label.justificativa', 1)) + " *"),"auto-grow":"","autofocus":"","rows":"1","counter":"250","rules":[_vm.rules.required, _vm.rules.maxLength250]},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.apuracoesImpactadas,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
                itemsPerPageOptions: [5, 10, 20, 30],
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cod_apuracao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.codApuracao != 0 ? item.codApuracao : _vm.$t('label.nova'))+" ")]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(("status_entidade." + (item.status.toLowerCase())), 1))+" ")]}},{key:"item.data_inicio",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataInicio)+" ")]}},{key:"item.data_fim",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataFim)+" ")]}},{key:"item.atualiza_apuracao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.atualizaApuracao ? _vm.$t('label.impacto_apuracoes_agrupadas_atualizacao') : _vm.$t('label.impacto_apuracoes_agrupadas_inclusao'))+" ")]}}],null,false,3815875278)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.fechar.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.confirmarCancelamento.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.salvar')))])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }