import PlanejamentoContratoValidacaoModal from './PlanejamentoContratoValidacaoModal';

export default {
  componentes: {
    PlanejamentoContratoValidacaoModal,
  },
  validacaoContrato(ref, configuracao) { // eslint-disable-line
    return [
      {
        habilitado: true,
        label: ref.$tc('label.contrato_identificado', 1),
        componente: 'planejamento-contrato-validacao-modal',
      },
    ];
  },
};
