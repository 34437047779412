<template>
  <v-dialog v-model="exibeModal" width="350px" max-width="350px">
    <container class="PlanejamentoContratoValidacaoModal">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('label.contrato_identificado') }}</span>
        </v-card-title>
        <v-form ref="formModal" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12">
                {{ msg }}
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              @click="close">{{ $t('label.cancelar') }}</v-btn>
            <v-btn
              color="primary"
              @click="continuar" >{{ $t('label.continuar') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </container>
  </v-dialog>

</template>
<script>

export default {
  name: 'PlanejamentoContratoValidacaoModal',
  props: {
    somenteLeitura: Boolean,
    novoContrato: Boolean,
    configuracao: {
      type: Object,
      default: () => {},
    },
    focoContrato: {
      type: Object,
      default: () => {},
    },
    entidade: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
  },
  data() {
    return {
      akzoPlanejamentoContratoResource: this.$api.akzoPlanejamentoContrato(this.$resource),

      exibeModal: false,
      msg: null,
      contratoId: null,
    };
  },
  watch: {
    entidade() {
      if (!this.novoContrato) return;
      this.existeContrato();
    },
  },
  methods: {
    continuar() {
      this.close();
      this.redirecionaDetalheContrato();
    },
    close() {
      this.exibeModal = false;
    },
    existeContrato() {
      const { valor } = this.focoContrato;
      if (!valor || !this.entidade) return;

      const params = {
        foco: valor,
        entidadeId: this.entidade.id,
        tipoContratoId: this.configuracao.configuracao.passo1.id,
      };

      this.akzoPlanejamentoContratoResource.existeContrato(params, this.$resource)
        .then((response) => {
          if (!response.data) return;

          this.exibeModal = true;
          this.contratoId = response.data.contratoId;
          this.obtemMensagemExibicao(response.data.codNome);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    obtemMensagemExibicao(codNome) {
      this.msg = this.$t('message.validacao_contrato_mensagem')
        .replace('[tipo_contrato]', this.configuracao.configuracao.passo1.nome)
        .replace('[tipo_foco]', this.focoContrato.texto)
        .replace('[nome_cliente]', codNome);
    },
    redirecionaDetalheContrato() {
      this.$router.push({ name: 'detalharContrato', params: { idContrato: this.contratoId } });

      setTimeout(() => {
        this.$router.go();
      }, 500);
    },
  },
};
</script>
<style lang="css">
  .PlanejamentoContratoValidacaoModal .v-card-title {
    padding: 0;
  }
</style>
