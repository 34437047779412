var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.acaoSelecionada)?_c('v-dialog',{staticStyle:{"max-height":"400px"},attrs:{"max-width":"1200px"},model:{value:(_vm.dialogApuracoesAgrupadas),callback:function ($$v) {_vm.dialogApuracoesAgrupadas=$$v},expression:"dialogApuracoesAgrupadas"}},[_c('v-card',{staticClass:"mr-1 mt-1 mb-1"},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$t('title.impacto_apuracoes_agrupadas', { tipoAcao: _vm.acaoAgrupadora.tipoAcao, tipoAcaoAgrupadora: _vm.acaoAgrupadora.tipoAcaoAgrupadora, } )))])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.apuracoesAgrupadas,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
            itemsPerPageOptions: [5, 10, 20, 30],
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cod_apuracao",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.codApuracao != 0 ? item.codApuracao : _vm.$t('label.nova'))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(("status_entidade." + (item.status.toLowerCase())), 1))+" ")]}},{key:"item.data_inicio",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataInicio)+" ")]}},{key:"item.data_fim",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataFim)+" ")]}},{key:"item.atualiza_apuracao",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.atualizaApuracao ? _vm.$t('label.impacto_apuracoes_agrupadas_atualizacao') : _vm.$t('label.impacto_apuracoes_agrupadas_inclusao'))+" ")]}}],null,false,3815875278)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.nao')))]),_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.solicitarAprovacao.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('label.sim')))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }